var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.userData && _vm.$can("update", "users")
    ? _c(
        "div",
        [
          _c(
            "b-row",
            { staticClass: "content-header" },
            [
              _c(
                "b-col",
                {
                  staticClass: "content-header-left mb-2",
                  attrs: { cols: "12", md: "9" }
                },
                [
                  _c(
                    "b-row",
                    { staticClass: "breadcrumbs-top" },
                    [
                      _c("b-col", { attrs: { cols: "12" } }, [
                        _c(
                          "h2",
                          {
                            staticClass:
                              "content-header-title float-left pr-1 mb-0"
                          },
                          [_vm._v(" Users ")]
                        ),
                        _c(
                          "div",
                          { staticClass: "breadcrumb-wrapper" },
                          [
                            _c(
                              "b-breadcrumb",
                              [
                                _c(
                                  "b-breadcrumb-item",
                                  { attrs: { to: "/apps/users/list" } },
                                  [
                                    _c("feather-icon", {
                                      staticClass: "align-text-top",
                                      attrs: { icon: "HomeIcon", size: "16" }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "b-breadcrumb-item",
                                  { attrs: { to: "/apps/users/list" } },
                                  [_vm._v(" Users ")]
                                ),
                                _c(
                                  "b-breadcrumb-item",
                                  {
                                    attrs: {
                                      to:
                                        "/apps/users/view/" +
                                        _vm.userData.username
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.userData.username) + " "
                                    )
                                  ]
                                ),
                                _c(
                                  "b-breadcrumb-item",
                                  { attrs: { active: "" } },
                                  [_vm._v(" Edit User ")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            _vm.userData === undefined ? "div" : "b-card",
            { tag: "component" },
            [
              _c(
                "b-alert",
                {
                  attrs: { variant: "danger", show: _vm.userData === undefined }
                },
                [
                  _c("h4", { staticClass: "alert-heading" }, [
                    _vm._v(" Error fetching user data ")
                  ]),
                  _c("div", { staticClass: "alert-body" }, [
                    _vm._v(" No user found with this username. ")
                  ])
                ]
              ),
              _c("user-edit-tab-account", {
                staticClass: "mt-2 pt-75",
                attrs: {
                  "user-data": _vm.userData,
                  "client-name-prop": _vm.clientNameProp
                }
              })
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "24", xl: "12", lg: "12", md: "12" } },
                [
                  _c("user-edit-user-permissions-card", {
                    attrs: { "user-data": _vm.userData }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm.userData.cognito_group === "Guard" ||
          _vm.userData.cognito_group === "Supervisor" ||
          _vm.userData.cognito_group === "Client_Viewer" ||
          _vm.userData.cognito_group === "Client_Admin"
            ? _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "24", xl: "12", lg: "12", md: "12" } },
                    [
                      _c("user-edit-client-permissions-card", {
                        attrs: { "user-data": _vm.userData }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }