var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$can("update", "users") && _vm.showPermissions
    ? _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c("b-card-header", { staticClass: "pb-50" }, [
            _c("h5", [_vm._v(" Permissions ")])
          ]),
          _vm.showAbilitiesTemplatesList
            ? _c(
                "b-card-body",
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        {
                          staticClass: "mb-md-0 mb-2",
                          attrs: { cols: "12", md: "4" }
                        },
                        [
                          _c("label", [_vm._v("Permissions Template")]),
                          _c("v-select", {
                            staticClass: "w-100",
                            attrs: {
                              dir: _vm.$store.state.appConfig.isRTL
                                ? "rtl"
                                : "ltr",
                              options: _vm.abilitiesTemplatesList
                            },
                            on: {
                              input: function(val) {
                                return _vm.selectTemplate(val)
                              }
                            },
                            model: {
                              value: _vm.selectedTemplate,
                              callback: function($$v) {
                                _vm.selectedTemplate = $$v
                              },
                              expression: "selectedTemplate"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c("b-table", {
            staticClass: "mb-0",
            attrs: { striped: "", responsive: "", items: _vm.permissionsData },
            scopedSlots: _vm._u(
              [
                {
                  key: "cell(module)",
                  fn: function(data) {
                    return [_vm._v(" " + _vm._s(data.value) + " ")]
                  }
                },
                {
                  key: "cell()",
                  fn: function(data) {
                    return [
                      _c("b-form-checkbox", {
                        attrs: {
                          id: data.value.name,
                          name: data.value.name,
                          checked: data.value.value,
                          value: data.value.checkedvalue,
                          "unchecked-value": data.value.uncheckedvalue,
                          disabled: data.value.disabledState
                        },
                        on: {
                          change: function($event) {
                            return _vm.updatePermissions(true)
                          }
                        },
                        model: {
                          value: _vm.selected,
                          callback: function($$v) {
                            _vm.selected = $$v
                          },
                          expression: "selected"
                        }
                      })
                    ]
                  }
                }
              ],
              null,
              false,
              2970085225
            )
          }),
          _c(
            "b-card-body",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _vm.$can("update", "users")
                        ? _c(
                            "b-button",
                            {
                              staticClass: "mb-1 mb-sm-0 mr-0 mr-sm-1",
                              attrs: { variant: "primary", block: "" },
                              on: { click: _vm.submitPermissions }
                            },
                            [_vm._v(" Update Permissions ")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }